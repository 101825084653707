import Player from '@vimeo/player';
import { httpsCallable } from 'firebase/functions';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  Routes, Route, useParams, Outlet, NavLink, Link, useNavigate,
} from 'react-router-dom';

import { Functions } from './FirebaseConfig';
import { LoginContext } from './LoginContext';
import { EditableNumberField, EditableTextField } from './Forms';


const PlaceHolderVideoUrl = (
  "https://player.vimeo.com/video/829299346?h=ea811b7258"
);
const listCourses = httpsCallable(Functions(), "listcourses");
const listCourseVideos = httpsCallable(Functions(), "listcoursevideos");
const setCourseVideo = httpsCallable(Functions(), "setcoursevideo");
const CourseContext = createContext(null);


function CourseLibrary(props) {
  const loadingMessage = "course data loading";
  const { activeRole } = props;
  const loginContext = useContext(LoginContext);
  const [courses, setCourses] = useState([]);
  const [coursesLoadError, setCourseLoadError] = useState(loadingMessage);
  const { courseAccessIds, userRoles, claims } = loginContext;

  useEffect(() => {
    let ignore = false;
    let data = {};
    setCourses([]);
    setCourseLoadError(loadingMessage);

    if (claims.admin && !["admin", "expert"].includes(activeRole)) {
      data["courseAccessIds"] = courseAccessIds;
    }

    listCourses(data).then(result => {
      if (!ignore) {
        setCourses(result.data.courses);
        setCourseLoadError("");
      }
    }).catch(e => {
      if (!ignore) {
        setCourses([]);
        setCourseLoadError(e.message);
      }
    });

    return () => {
      ignore = true;
    };
  }, [activeRole, userRoles, courseAccessIds, claims]);

  if (!loginContext.loggedIn) {
    return (
      <div>
        <p>You are not signed in. Please sign in to view course library."</p>
      </div>
    );
  }

  if (
    ![
      "consultant", "student", "parent", "expert", "admin",
    ].includes(activeRole)
  ) {
    return (
      <div>
        <h2>Course Library</h2>
        <NoCourseAccess />
      </div>
    );
  }

  if (coursesLoadError) {
    return (
      <div>
        <h2>Course Library</h2>
        <div className="errorText">{coursesLoadError}</div>
      </div>
    );
  }

  if (!courses.length) {
    return (
      <div>
        <h2>Course Library</h2>
        <div className="errorText">
          No courses are available on this account
        </div>
      </div>
    );
  }

  const titles = {
    "consultant": "Consultant Course Library",
    "student": "Student Course Library",
    "parent": "Parent Course Library",
    "expert": "Expert Course Library",
    "admin": "Admin Course Library",
  }

  return (
    <div>
      <h2>{titles[activeRole]}</h2>
      <Routes>
        <Route
          index element={
            activeRole === "consultant"
              ? <NoCourseAccess />
              : <DynamicAccess courses={courses} />
          }
        />
        <Route
          path="courses/:courseName/*"
          element={
            <CoursePane
              courses={courses}
              activeRole={activeRole}
              courseAccessIds={loginContext.courseAccessIds} />
          }
        />
      </Routes>
      <Outlet />
    </div>
  );
}

function useCourseVideos(courseName, activeRole, courseAccessIds, refresher) {
  const [videoData, setVideoData] = useState(null);
  const [videoDataError, setVideoDataError] = useState("");

  useEffect(() => {
    let ignore = false;
    const listVideos = async () => {
      setVideoData(null);
      setVideoDataError("");

      const result = await listCourseVideos({
        courseName: courseName,
        activeRole: activeRole,
        courseAccessIds: courseAccessIds,
      });
      const videoDocs = result.data.videos;
      if (videoDocs.length < 1) {
        setVideoData({});
        return;
      }

      let newVideoData = {};
      for (const videoDoc of videoDocs) {
        newVideoData[videoDoc.name || videoDoc._docId] = videoDoc;
      }

      if (ignore === false) {
        setVideoData(newVideoData);
      }
    };

    listVideos().catch(e => {
      if (!ignore) {
        setVideoDataError(e.message);
      }
    });
    return () => {
      ignore = true;
    };
  }, [courseName, activeRole, courseAccessIds, refresher]);

  return [videoData, videoDataError];
}

function CoursePane(props) {
  const params = useParams();
  const [courseContext, setCourseContext] = useState({
    refreshIncrement: 0,
    videoData: {},
    refreshData: () => {
      setCourseContext(
        c => Object.assign({}, c, { refreshIncrement: c.refreshIncrement + 1 })
      );
    },
  });
  const [videoData, videoDataError] = useCourseVideos(
    params.courseName,
    props.activeRole,
    props.courseAccessIds,
    courseContext.refreshIncrement,
  );

  useEffect(() => {
    setCourseContext(c => Object.assign({}, c, { videoData: videoData }));
  }, [setCourseContext, videoData]);

  if (videoDataError) {
    return (
      <div>
        <Link to="/course-library">{"\u21a9"} Back to Course Library</Link>
        <h3 className="errorText">
          Error loading course data: {videoDataError}
        </h3>
      </div>
    );
  }

  let navLinks = [];
  let loaded = true;
  let loadError = false;

  if (videoData === null) {
    loaded = false;
  } else if (Object.keys(videoData).length === 0) {
    loadError = true;
  } else {
    // Create the NavLinks
    for (const videoName in videoData) {
      const target = (
        `/course-library/courses/${params.courseName}/videos/${videoName}`
      );
      navLinks.push(
        <NavLink to={target} key={target}>{videoName}</NavLink>
      );
    }
  }

  if (loadError) {
    return (
      <div>
        <Link to="/course-library">{"\u21a9"} Back to Course Library</Link>
        <h3 className="errorText">
          Invalid course name...
        </h3>
      </div>
    );
  }

  let navSection = <div>Attempting to load videos...</div>;
  if (loaded === true) {
    navSection = <nav className="videoNav">{navLinks}</nav>;
  }

  return (
    <CourseContext.Provider value={courseContext}>
      <div>
        <Link to="/course-library">{"\u21a9"} Back to Course Library</Link>
        <h3 className="bolded capitalized">
          {params.courseName}
        </h3>
        <Routes>
          <Route
            path="videos/:videoId"
            element={
              <VideoPane videoData={videoData} activeRole={props.activeRole} />
            }
          />
        </Routes>
        <Outlet />
        {navSection}
        {
          props.activeRole === "admin" && <div>
            <h3>Add Video to Course</h3>
            <CourseVideoForm name="" activeRole={props.activeRole} />
          </div>
        }
      </div>
    </CourseContext.Provider>
  );
}

function VideoPane(props) {
  const params = useParams();
  const courseContext = useContext(CourseContext);
  const { videoId, courseName } = params;
  const { activeRole } = props;

  if (!Object.keys(courseContext?.videoData || {}).length) {
    return (
      <div>
        <h3 className="bolded capitalized">
          {videoId}
        </h3>
        <div className="errorText">
          No video data loaded
        </div>
      </div>
    );
  }

  const { videoData } = courseContext;

  return (
    <div>
      <h3 className="bolded capitalized">
        {videoId}
      </h3>
      <CourseVideoForm name={videoId} activeRole={activeRole} />
      <Video
        courseName={courseName}
        videoId={videoId}
        videoData={videoData} />
    </div>
  );
}

function CourseVideoForm(props) {
  const { name, activeRole } = props;
  const courseContext = useContext(CourseContext);
  const { loggedIn, userRoles } = useContext(LoginContext);
  const { courseName } = useParams();
  const navigate = useNavigate();

  const videoData = name ? courseContext.videoData[name] : null;
  const isAdmin = loggedIn && userRoles.includes("admin")

  const origUrl = videoData?.href || "";
  const origVideoIndex = videoData?.order || 0;
  const origStartMonth = videoData?.startMonth || 0;
  const origStartDay = videoData?.startDayOfMonth || 0;

  const [thisName, setThisName] = useState(name);
  const [thisUrl, setThisUrl] = useState(origUrl);
  const [thisVideoIndex, setThisVideoIndex] = useState(origVideoIndex);
  const [thisStartMonth, setThisStartMonth] = useState(origStartMonth);
  const [thisStartDay, setThisStartDay] = useState(origStartDay);
  const [editable, setEditable] = useState(true);

  const resetComponent = () => {
    setThisName(name);
    setThisUrl(origUrl);
    setThisVideoIndex(origVideoIndex);
    setThisStartMonth(origStartMonth);
    setThisStartDay(origStartDay);
    setEditable(true);
  };

  const requestSave = () => {
    setEditable(false);
    const oldVideo = videoData
      ? Object.assign({}, videoData, { name: name, course: courseName })
      : null;
    const newVideo = {
      name: thisName,
      course: courseName,
      href: thisUrl,
      order: thisVideoIndex,
      startMonth: thisStartMonth,
      startDayOfMonth: thisStartDay,
    };
    setCourseVideo({ old: oldVideo, new: newVideo }).then(result => {
      if ((oldVideo?.name || "") !== newVideo.name) {
        navigate(`/course-library/courses/${courseName}`);
      }
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      resetComponent();
      courseContext.refreshData();
    });
  };

  if (!isAdmin || activeRole !== "admin") {
    return <></>;
  }

  return (
    <div className="customForm">
      <EditableTextField
        label="Name"
        modified={name !== thisName}
        editable={editable}
        fieldName="name"
        fieldType="text"
        useRef={null}
        fieldValue={thisName}
        useKeyUp={null}
        setValue={setThisName} />
      <EditableTextField
        label="Video URL"
        modified={thisUrl !== origUrl}
        editable={editable}
        fieldName="videoURL"
        fieldType="text"
        useRef={null}
        fieldValue={thisUrl}
        useKeyUp={null}
        setValue={setThisUrl} />
      <EditableNumberField
        label="Course Video Number"
        modified={thisVideoIndex !== origVideoIndex}
        editable={editable}
        fieldName="courseVideoNumber"
        useRef={null}
        fieldValue={thisVideoIndex}
        useKeyUp={null}
        setValue={setThisVideoIndex} />
      <EditableNumberField
        label="Drip Start Month"
        modified={thisStartMonth !== origStartMonth}
        editable={editable}
        fieldName="dripStartMonth"
        useRef={null}
        fieldValue={thisStartMonth}
        useKeyUp={null}
        setValue={setThisStartMonth} />
      <EditableNumberField
        label="Drip Start Day"
        modified={thisStartDay !== origStartDay}
        editable={editable}
        fieldName="dripStartDay"
        useRef={null}
        fieldValue={thisStartDay}
        useKeyUp={null}
        setValue={setThisStartDay} />
      <div className="buttonArea">
        <button disabled={!editable} onClick={e => {
          e.preventDefault();
          requestSave();
        }}>Save</button>
        <button disabled={!editable} onClick={e => {
          e.preventDefault();
          resetComponent();
        }}>Reset</button>
      </div>
    </div>
  );
}

function useVideoPlayer(divId) {
  const [player, setPlayer] = useState(null);
  useEffect(() => {
    let ignore = false;
    // This should never be displayed to the user, but it necessary to create a
    // vimeo player independent of the course url
    const newPlayer = new Player(divId, {
      url: PlaceHolderVideoUrl,
      width: 800,
      height: 400,
      responsive: true,
    });

    if (!ignore) {
      setPlayer(newPlayer);
    }
    return () => {
      ignore = true;
    }
  }, [divId]);
  return player;
}

function Video(props) {
  const videoDivId = "courseVideoElement";
  const player = useVideoPlayer(videoDivId);
  let href = "";
  if (props.videoData) {
    href = props.videoData[props.videoId].href;
  }

  useEffect(() => {
    if (href === "" || player === null) {
      return;
    }
    player.loadVideo(href);
  }, [href, player]);

  return <div id={videoDivId} />;
}

function NoCourseAccess() {
  return (
    <div>
      <p>You don't have access to the course library.</p>
    </div>
  );
}


function DynamicAccess(props) {
  const { courses } = props;

  return (
    <nav className="videoNav">
      {courses.map((c, i) => (
        <NavLink to={`courses/${c}`} key={`courses:${i}`}>{c}</NavLink>)
      )}
    </nav>
  );
}


export { CourseLibrary };