import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";

import { Alert } from "./Alert";
import { Auth } from './FirebaseConfig';
import {
  isSignInWithEmailLink,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { LoginContext } from "./LoginContext";

import "./Login.css";


function EmailPasswordSignin() {
  const emailAddressRef = useRef();
  const passwordRef = useRef();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertTimeout, setAlertTimeout] = useState(-1);
  const navigate = useNavigate();

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      emailAddressRef.current.focus();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const login = async () => {
    try {
      // Like with other forms of login/logout, perhaps we should explicitly
      // clear the URL state/params here
      await signInWithEmailAndPassword(Auth(), emailAddress, password);
      navigate("/");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(`Error Code: ${errorCode}`);
      console.log(`Error Message: ${errorMessage}`);
      setAlertTimeout(5000);
      setAlertText(
        "Could not log in - incorrect username and/or password. Please try again."
      );
      return;
    }
  }

  return (
    <>
      <div className="bareForm">
        <input
          className="textField"
          name="emailAddress"
          type="email"
          placeholder="Email Address"
          ref={emailAddressRef}
          value={emailAddress}
          onChange={e => {
            e.preventDefault();
            setEmailAddress(e.target.value);
          }}
          onKeyUp={e => {
            if (e.key === "Enter") {
              e.preventDefault();
              passwordRef.current.focus();
            }
          }} />
        <input
          className="textField"
          name="password"
          type="password"
          placeholder="Password"
          ref={passwordRef}
          value={password}
          onChange={e => {
            e.preventDefault();
            setPassword(e.target.value);
          }}
          onKeyUp={async (event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              await login(event);
            }
          }} />
      </div>
      <div className="otherComponents">
        <button onClick={async e => {
          e.preventDefault();
          await login();
        }}>
          Login with Email and Password
        </button>
      </div>
      <Alert
        text={alertText}
        timeout={alertTimeout}
        disabled={alertTimeout < 0}
        isError={true}
        onExpired={() => { setAlertTimeout(-1); }} />
    </>
  );
}

// For now, this is an invisible component that simply handles sign-in links; it
// could do other things, or provide feedback, but for now, it's purely
// functional
function HandleEmailSigninLink() {
  const loginContext = useContext(LoginContext);
  const [alertText, setAlertText] = useState("");
  const [alertTimeout, setAlertTimeout] = useState(-1);
  const [alertAction, setAlertAction] = useState("");
  const [signInEmail, setSignInEmail] = useState("");

  // Used to navigate the user back to home on successful sign-in
  const navigate = useNavigate();

  // Get the email if available. This should be available if the user
  // initiated the process recently in the same browser
  useEffect(() => {
    let ignore = false;

    let email = window.localStorage.getItem("emailForSignIn");
    if (email) {
      console.debug(`found email locally: ${email}`);
      // Signed in. Remove the sign-in artifact, if it existed, redirect to
      // the home page
      if (!ignore) {
        window.localStorage.removeItem("emailForSignIn");
        setSignInEmail(email);
      }
    }

    return () => {
      ignore = true;
    };
  }, []);

  // Primary functionality; checks to see if the page/route was called with
  // login information. If it was, process it, attempt a login, and finally
  // redirect back to / if successful
  useEffect(() => {
    console.debug(`sign-in handler is called at ${window.location.href}`);
    let ignore = false;

    // Double-check we're not already logged in, and that the URL looks like a
    // sign-in URL
    if (
      loginContext.loggedIn
      || !isSignInWithEmailLink(Auth(), window.location.href)
    ) {
      return;
    }

    if (!signInEmail || ignore) {
      return;
    }

    console.debug("attempting login");

    signInWithEmailLink(Auth(), signInEmail, window.location.href)
      .then(() => {
        console.debug("signed-in successful");

        // When an active sign-in occurs, we'll clear the parameter string in
        // the URL to start things fresh. During the initial load, where we
        // don't know if we're signed in yet, we can preserve them
        navigate("/");
      })
      .catch(error => {
        console.debug(error);
        console.debug(error.code);
        if (error.code === "auth/invalid-email" && !ignore) {
          setAlertTimeout(5000);
          setAlertText(
            "The email address entered does not match our records."
            + " Please try again."
          );
          setAlertAction("reload");
        } else if (
          (
            error.code === "auth/invalid-action-code"
            || error.code === "auth/expired-action-code"
          ) && !ignore
        ) {
          setAlertTimeout(5000);
          setAlertText(
            "This sign-in link has expired or is invalid. "
            + "Please request a new one from the login page.");
          setAlertAction("root_navigate");
        } else if (!ignore) {
          setAlertTimeout(5000);
          setAlertText(
            `An unknown error occurred: ${error.code}; `
            + "please try again or reach out if this keeps happening");
          setAlertAction("");
        }
      });

    return () => {
      ignore = true;
    };
  }, [loginContext, navigate, signInEmail]);

  console.debug("rendering (invisible) sign-in handler");

  return (
    <>
      <div className="otherComponents">
        Completing the sign-in process...
      </div>
      <Alert
        text={alertText}
        timeout={alertTimeout}
        disabled={alertTimeout < 0}
        isError={true}
        onExpired={() => {
          setAlertTimeout(-1);
          if (alertAction === "reload") {
            window.location.reload();
          } else if (alertAction === "root_navigate") {
            navigate("/");
          }
        }} />
    </>
  );
}

function SignInWithEmailLink() {
  const [emailAddress, setEmailAddress] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertTimeout, setAlertTimeout] = useState(-1);
  const [alertAction, setAlertAction] = useState("");
  const [alertError, setAlertError] = useState(false);
  const navigate = useNavigate();
  const emailAddressRef = useRef();

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      emailAddressRef.current.focus();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const sendSignInLink = async (event) => {
    event.preventDefault();
    console.info("sign-in link requested");

    const baseUrl = window.location.origin;
    const redirectUrl = `${baseUrl}/login/finish-email-signin`;
    console.debug(`redirectUrl: ${redirectUrl}`);

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: redirectUrl,
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(
        Auth(), emailAddress, actionCodeSettings);
    } catch (error) {
      if (error.code === "auth/admin-restricted-operation") {
        setAlertText(
          "The entered email does not have a registered account and cannot be"
          + " reset or used to login. Please enter an email registered with the"
          + " program");
        setAlertTimeout(5000);
        setAlertAction("");
        setAlertError(true);
        return;
      }
      setAlertText(
        "An unknown error has occurred; please try again or reach out to your"
        + " organization representative");
      setAlertTimeout(5000);
      setAlertAction("");
      setAlertError(true);
      return;
    }

    // Save the input email to the browser so that the user doesn't need to
    // enter it again later, assuming they go grab the link and come back with
    // it in a reasonable amount of time
    window.localStorage.setItem("emailForSignIn", emailAddress);

    setAlertText(
      "Your request has been received and you should receive a sign-in email "
      + "shortly"
    );
    setAlertTimeout(10000);
    setAlertAction("root_navigate");
    setAlertError(false);
  }

  return (
    <>
      <div className="bareForm">
        <input
          className="textField"
          disabled={alertTimeout > 0}
          placeholder="Email Address"
          name="emailAddress"
          type="email"
          value={emailAddress}
          ref={emailAddressRef}
          onChange={e => {
            e.preventDefault();
            setEmailAddress(e.target.value);
          }}
          onKeyUp={async (event) => {
            if (event.key === "Enter") {
              await sendSignInLink(event);
            }
          }} />
      </div>
      <div className="otherComponents">
        <button onClick={sendSignInLink}>Send sign-in link to email</button>
      </div>
      <div>
        <Link to="with-password">or sign in another way</Link>
      </div>
      <Alert
        text={alertText}
        timeout={alertTimeout}
        disabled={alertTimeout < 0}
        isError={alertError}
        onExpired={() => {
          if (alertAction === "root_navigate") {
            navigate("/");
          }
          setAlertTimeout(-1);
        }} />
    </>
  );
}

function Login() {
  return (
    <>
      <Outlet />
      <Routes>
        <Route
          index={true}
          element={<SignInWithEmailLink />} />
        <Route
          path="finish-email-signin"
          element={<HandleEmailSigninLink />} />
        <Route
          path="with-password"
          element={<EmailPasswordSignin />} />
      </Routes>
    </>
  );
}

export { Login };
