import React, { useContext, useRef, useState } from "react";
import { passwordStrength } from "check-password-strength";

import { Alert } from "./Alert";
import { EditableTextField } from "./Forms";
import { LoginContext } from "./LoginContext";


function AccountSettings() {
  return (
    <div>
      <h2>Account Settings</h2>
      <AccountSettingsFields />
    </div>
  );
}


function AccountSettingsFields() {
  const loginContext = useContext(LoginContext);

  const {
    userFirstName: origFirstName,
    userLastName: origLastName,
    userEmail: origEmail,
    updateFirstName,
    updateLastName,
    updateEmail,
    updatePassword,
    loggedIn,
  } = loginContext;

  const [fieldsEditable, setFieldsEditable] = useState(false);
  const [userFirstName, setUserFirstName] = useState(origFirstName);
  const [userLastName, setUserLastName] = useState(origLastName);
  const [userEmail, setUserEmail] = useState(origEmail);
  const [userPassword, setUserPassword] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertTimeout, setAlertTimeout] = useState(-1);
  const [alertError, setAlertError] = useState(false);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailAddressRef = useRef();
  const passwordRef = useRef();

  const resetComponent = () => {
    setUserFirstName(origFirstName);
    setUserLastName(origLastName);
    setUserEmail(origEmail);
    setUserPassword("");
    setFieldsEditable(false);
    setAlertText("");
    setAlertTimeout(-1);
    setAlertError(false);
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    let changesSaved = false;
    if (userFirstName !== origFirstName) {
      console.log(`Updating first name: ${origFirstName} -> ${userFirstName}`);
      await updateFirstName(userFirstName);
      changesSaved = true;
    }
    if (userLastName !== origLastName) {
      console.log(`Updating last name: ${origLastName} -> ${userLastName}`);
      await updateLastName(userLastName);
      changesSaved = true;
    }
    if (userEmail !== origEmail) {
      console.log(`Updating email address: ${origEmail} -> ${userEmail}`);
      await updateEmail(userEmail);
      changesSaved = true;
    }
    if (userPassword !== "") {
      if (passwordStrength(userPassword).id >= 3) {
        console.log("Updating password.");
        await updatePassword(userPassword);
        setUserPassword("");
        changesSaved = true;
      } else {
        setAlertText(
          `Password must be stronger. Please ensure your password is at least
          10 characters long, and please ensure it contains at least one
          lowercase letter, one uppercase letter, one number, and one
          symbol (such as these: !, $, %, &, #, etc).`
        );
        setAlertTimeout(8000);
        setAlertError(true);
        return;
      }
    }
    firstNameRef.current = null;  // manually tracking focus
    setFieldsEditable(false);
    if (changesSaved) {
      setAlertText("Your changes have been saved!");
      setAlertTimeout(3000);
      setAlertError(false);
    }
  }

  const focusNextComponentOnEnter = (next) => {
    return async (event) => {
      if (event.key === "Enter") {
        next.current.focus();
      }
    };
  }

  if (!loggedIn) {
    throw new Error("must be logged in to view account settings");
  }

  return (
    <>
      <div className="customForm">
        <EditableTextField
          label="First Name"
          fieldName="userFirstName"
          fieldType="text"
          fieldValue={userFirstName}
          editable={fieldsEditable}
          modified={userFirstName !== origFirstName}
          useRef={(el) => {
            if (el !== null && firstNameRef.current === null) {
              el.focus();
              firstNameRef.current = el;
            }
          }}
          useKeyUp={focusNextComponentOnEnter(lastNameRef)}
          setValue={setUserFirstName}
        />
        <EditableTextField
          label="Last Name"
          fieldName="userLastName"
          fieldType="text"
          fieldValue={userLastName}
          editable={fieldsEditable}
          modified={userLastName !== origLastName}
          useRef={lastNameRef}
          useKeyUp={focusNextComponentOnEnter(emailAddressRef)}
          setValue={setUserLastName}
        />
        <EditableTextField
          label="Email Address"
          fieldName="userEmail"
          fieldType="email"
          fieldValue={userEmail}
          editable={fieldsEditable}
          modified={userEmail !== origEmail}
          useRef={emailAddressRef}
          useKeyUp={focusNextComponentOnEnter(passwordRef)}
          setValue={setUserEmail}
        />
        <EditableTextField
          label="Password"
          fieldName="userPassword"
          fieldType="password"
          fieldValue={fieldsEditable ? userPassword : "********"}
          editable={fieldsEditable}
          modified={userPassword !== ""}
          useRef={passwordRef}
          useKeyUp={async (event) => {
            if (event.key === "Enter") {
              await saveChanges(event);
            }
          }}
          setValue={setUserPassword}
        />
        {fieldsEditable && (
          <div className="fieldDescription">
            Leave password field blank if you don't want to change your password.
          </div>
        )}
        <div className="buttonArea">
          {fieldsEditable
            ? <>
              <button onClick={saveChanges}>Save Changes</button>
              <button onClick={e => {
                e.preventDefault();
                resetComponent();
              }}>Cancel</button>
            </>
            : <button
              onClick={e => {
                e.preventDefault();
                setFieldsEditable(true);
              }}
            >
              Update Information
            </button>
          }
        </div>
      </div>
      <Alert
        text={alertText}
        timeout={alertTimeout}
        disabled={alertTimeout <= 0}
        isError={alertError}
        onExpired={() => {
          setAlertTimeout(-1);
        }} />
    </>
  );
}


export { AccountSettings };
