import { useEffect, useRef } from "react";

import "./Alert.css";


function Alert(props) {
  const { text, timeout, disabled, onExpired, isError } = props;
  const alertRef = useRef(null);
  const normalTimeout = Math.max(timeout, 0);

  useEffect(() => {
    let ignore = false;
    let timerId = null;

    if (normalTimeout <= 0 || disabled || !alertRef.current) {
      return;
    }

    timerId = setTimeout(() => {
      if (!ignore) {
        onExpired();
      }
    }, normalTimeout);

    alertRef.current.focus();

    return () => {
      ignore = true;
      if (timerId) {
        clearTimeout(timerId);
      }
    }
  }, [normalTimeout, disabled, onExpired]);

  if (disabled) {
    return <></>;
  }

  const contentClasses = isError === true ? "content error" : "content info";

  return (
    <div
      className="alert withFocus"
      ref={alertRef}
      tabIndex={0}
      onClick={e => {
        e.preventDefault();
        onExpired();
      }}
      onKeyUp={e => {
        e.preventDefault();
        if (["Enter", "Escape", "Space"].includes(e.code)) {
          onExpired();
        }
      }}>
      <div className={contentClasses}>
        <h3>Notice</h3>
        <div>
          {text}
        </div>
      </div>
    </div>
  );
}


export { Alert };