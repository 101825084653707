import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { Checkbox, EditableTextField, SingleSelectField } from "./Forms";
import { LoginContext } from "./LoginContext";

function SignUp(props) {
  const { adminOnly } = props;
  const loginContext = useContext(LoginContext);

  if (adminOnly && !(loginContext?.loggedIn && loginContext?.claims?.admin)) {
    return <></>;
  }

  return (
    <>
      <Routes>
        <Route
          path="sign-up/*"
          element={<SignUpContent />}
        />
      </Routes>
    </>
  );
}

function SignUpContent() {
  return (
    <>
      <h2>Tell Us About You 🧐</h2>
      <SignUpForm />
      <h2>And the Money</h2>
      <p>
        You knew this was coming. We need mony, mostly to bribe admissions
        officers, but we also allocate some to pay our small staff.
      </p>
    </>
  )
}


function SignUpForm() {
  const firstNameRef = useRef();
  const [firstName, setFirstName] = useState("");
  const lastNameRef = useRef();
  const [lastName, setLastName] = useState("");
  const emailRef = useRef();
  const [email, setEmail] = useState("");
  const [graduationYear, setGraduationYear] = useState(null);
  const [ageEligible, setAgeEligible] = useState(false);
  const [role, setRole] = useState(null);
  const roles = ["Student", "Parent"];
  const graduationYears = useMemo(() => {
    const now = new Date();
    const nextYear = now.getMonth() > 4;
    const startYear = now.getFullYear() + (nextYear ? 1 : 0);
    return Array.from(Array(4).keys(), x => x + startYear);
  }, []);
  const yearToName = useCallback(year => {
    const keyToName = {
      0: "Senior",
      1: "Junior",
      2: "Sophomore",
      3: "Freshman",
    };
    const yearMap = graduationYears.reduce((cur, val, idx) => {
      cur[val] = `${val} (${keyToName[idx]})`;
      return cur;
    }, {});
    return yearMap[year];
  }, [graduationYears]);

  return (
    <>
      <div className="customForm">
        <Checkbox
          label="Are you at least 18 years old?"
          required={true}
          modified={ageEligible}
          prefix={`signUp:over18`}
          fieldName="over18"
          fieldValue={ageEligible}
          setValue={setAgeEligible}
        />
        <EditableTextField
          label="First Name"
          fieldName="userFirstName"
          fieldType="text"
          fieldValue={firstName}
          editable={ageEligible}
          required={true}
          modified={firstName !== ""}
          useRef={el => {
            if (el !== null && firstNameRef.current === null) {
              el.focus();
              firstNameRef.current = el;
            }
          }}
          useKeyUp={e => {
            if (e.key === "Enter") {
              lastNameRef.current.focus();
            }
          }}
          setValue={setFirstName}
        />
        <EditableTextField
          label="Last Name"
          fieldName="userLastName"
          fieldType="text"
          fieldValue={lastName}
          editable={ageEligible}
          required={true}
          modified={lastName !== ""}
          useRef={lastNameRef}
          useKeyUp={e => {
            if (e.key === "Enter") {
              emailRef.current.focus();
            }
          }}
          setValue={setLastName}
        />
        <EditableTextField
          label="Email"
          fieldName="userEmail"
          fieldType="text"
          fieldValue={email}
          editable={ageEligible}
          required={true}
          modified={email !== ""}
          useRef={emailRef}
          validationRegex={/^\s*(\w+@\w+\.[a-z]+)?\s*$/i}
          useKeyUp={e => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
          setValue={setEmail}
        />
        <SingleSelectField
          label="Graduation Year"
          editable={ageEligible}
          required={true}
          modified={graduationYears.includes(graduationYear)}
          prefix={`signUp:graduationYear`}
          value={graduationYear}
          choices={graduationYears}
          updateValue={setGraduationYear}
          valueTransformer={yearToName}
        />
        <SingleSelectField
          label="You are a ...?"
          editable={ageEligible}
          required={true}
          modified={roles.includes(role)}
          prefix={`signUp:role`}
          value={role}
          choices={roles}
          updateValue={setRole}
        />
      </div>
      {!ageEligible && (
        <div>
          <h3>Note</h3>
          <p>
            You must be 18 or older in order to complete this form. If under 18,
            you must get a parent/guardian to sign up first, then invite you to
            account.
          </p>
        </div>
      )}
      {ageEligible && role === "Student" && (
        <div>
          <h3>A Note to Students</h3>
          <p>
            If you're signing up for the first time, you're in the right place.
            But if you have a parent that's already signed up, it's better that
            they set up the account and invite you to join theirs.
          </p>
          <p>
            Alternately, you can sign up first, then invite your parents to join
            your account. How you do it is up to you and your family.
          </p>
        </div>
      )}
    </>
  );
}


export { SignUp };