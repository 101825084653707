import "./Forms.css";


function Checkbox(props) {
  const { label, fieldName, fieldValue, setValue, modified, required } = props;
  const baseClasses = required ? "checkbox required" : "checkbox";
  const classes = (
    modified ? `${baseClasses} modified`
      : baseClasses
  );
  return (
    <>
      <div className="formLabel">
        <label>{label}</label>
      </div>
      <div className="fieldContainer">
        <input
          className={classes}
          disabled={false}
          name={fieldName}
          value={fieldName}
          type="checkbox"
          checked={fieldValue}
          onClick={e => {
            setValue(!fieldValue)
          }}
        />
      </div>
    </>
  );
}

function EditableTextField(props) {
  const {
    required,
    fieldType,
    fieldName,
    fieldValue,
    modified,
    label,
    editable,
    validationRegex,
    useKeyUp,
    useRef,
    setValue,
  } = props;
  const matches = (validationRegex || /^.*$/).test(fieldValue);
  const baseClasses = required ? "textField required" : "textField"
  const classes = (
    !matches ? `${baseClasses} invalid`
      : modified ? `${baseClasses} modified`
        : baseClasses
  );
  return (
    <>
      <div className="formLabel">
        <label>{label}</label>
      </div>
      <input
        className={classes}
        disabled={!editable}
        name={fieldName}
        type={fieldType || "text"}
        ref={useRef}
        value={fieldValue}
        onKeyUp={useKeyUp}
        onChange={e => {
          e.preventDefault();
          setValue(e.target.value);
        }} />
    </>
  );
}

function EditableNumberField(props) {
  return (
    <EditableTextField
      label={props.label}
      modified={props.modified}
      editable={props.editable}
      fieldName={props.fieldName}
      fieldType="number"
      useRef={props.useRef}
      fieldValue={props.fieldValue.toString()}
      useKeyUp={props.useKeyUp}
      setValue={v => { props.setValue(parseInt(v || "0")) }} />
  );
}


function SingleSelectField(props) {
  const {
    label, required, editable, choices, modified, prefix, value, updateValue,
  } = props;
  const baseClasses = required ? "selectField required" : "selectField";
  const classes = (
    !editable ? `${baseClasses} disabled`
      : choices.length < 1 ? `${baseClasses} empty`
        : modified ? `${baseClasses} modified`
          : baseClasses
  );
  const prefixKey = `${prefix}:singleSelect`;
  const valueTransformer = props.valueTransformer || (v => v);

  const fieldValues = choices.map((c, i) => {
    return (
      <SelectFieldValue
        prefix={`${prefixKey}:${i}`}
        key={`${prefixKey}:${i}`}
        disabled={!editable}
        value={c}
        displayValue={valueTransformer(c)}
        chosen={value === c}
        updateValue={() => {
          updateValue(value === c ? null : c);
        }}
      />
    );
  });

  return (
    <>
      <div className="formLabel">
        <label>{label}</label>
      </div>
      <div className={classes}>
        {fieldValues.length > 0 ? fieldValues : "(None Specified)"}
      </div>
    </>
  );
}

function SelectFieldValue(props) {
  const { chosen, prefix, displayValue, updateValue, disabled } = props;
  const classes = (
    disabled ? "selectFieldChoice disabled"
      : chosen ? "selectFieldChoice selected"
        : "selectFieldChoice"
  );

  return (
    <div
      className={classes}
      key={`${prefix}:selectFieldChoice`}
      onClick={e => {
        e.preventDefault();
        if (!disabled) {
          updateValue();
        }
      }}>
      {displayValue}
    </div>
  );
}


export { Checkbox, EditableNumberField, EditableTextField, SingleSelectField };